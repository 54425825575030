<template>
  <div class="login">
    <Headers :name="'用户信息更改'"></Headers>
    <div class="logo"></div>
    <div class="login-body login">
      <van-form @submit="register" key="formKey" ref="loginFormRef">
          <van-field  v-model="information.nickname" disabled  required clearable  placeholder="请输入姓名"
           :rules="[{ validator:sss, message: '填写有误，请勿输入特殊字符' }]">
           <i slot="left-icon" class="icon-yonghu iconfont inc"></i>
          </van-field>
           <van-field  v-model="information.phone" disabled  required clearable  placeholder="请输入手机号"
           :rules="[{ validator:iphone, message: '填写有误，请输入正确的号码' }]">
            <i slot="left-icon" class="icon-shouji iconfont inc"></i>
          </van-field>
           <van-field  v-model="information.work_unit" required clearable  placeholder="请输入单位名称"
           :rules="[{ required: true, message: '请填写单位名称' }]">
            <i slot="left-icon" class="icon-gongsi iconfont inc"></i>
            
          </van-field>

          <!-- @click="clickshow" -->
          <van-field  v-model="information.typeNmae"  required is-link readonly  placeholder="请选择人员类型"  :rules="[{ required: true, message: '请选择人员类型' }]" >
            <i slot="left-icon" class="iconfont icon-renyuanleixing  inc"></i>
           </van-field>

          <van-field v-model="fieldValue" required is-link readonly  placeholder="请选择药店所属地区"
          :rules="[{ required: true, message: '请选择药店所属地区' }]"
            @click="show = true" >
            <i slot="left-icon" class="iconfont icon-shengfenzuobiao_mianxing  inc"></i>
          </van-field>
        <div class="magsd">
          <van-button round block type="info" color="#00BAFF" native-type="submit">确定修改</van-button>
        </div>
      </van-form>
    </div>
    
    <van-popup v-model="show" round position="bottom">
      <van-cascader  v-model="cascaderValue" title="请选择所属地区"  :options="options"
        @close="show = false"  @finish="onFinish" />
    </van-popup>
   
    <van-popup v-model="showPickers" round position="bottom">
      <van-picker show-toolbar :columns="columns"  @cancel="showPickers = false"  @confirm="onConfirm"  />
    </van-popup>
  </div>
</template>

<script>
import Headers from '@/components/SimpleHeader';
import { regionList, editInfo } from '../../service/userLogin';
import { userinfo } from '../../service/user'
import { setLocal, getLocal } from '@/common/js/utils'
import { Toast } from 'vant'
export default {
  components: {
    Headers
  },
  data() {
    return {
      showPickers: false,
      show: false,
      cascaderValue: '',
      options: [],
      fieldValue: '',
      information: {
        nickname: '', // 用户名
        phone: '', // 手机号
        work_unit: '', // 单位
        password: '', // 密码
        city: '', // 市
        county: '', // 区
        town: '', // 街道
        user_type: '', // 用户类型
        typeNmae: '医药从业人员',
        user_id: sessionStorage.getItem('userId-manage'),
      },
      user: {},
      columns: [ '医药从业人员', '市场监管人员' ],
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    sss(val) {
      // console.log(val,'val');
      return /^([\u4e00-\u9fa5]|[a-zA-Z0-9])+$/.test(val);
    },
    iphone(val) {
      // console.log(val,'val');
      // 验证一个手机号：第一位是1，第二位是3-9，以后每一位都是0-9
      // /^[1][3,4,5,7,8][0-9]{9}$/
      return /^[1][3,4,5,7,8][0-9]{9}$/.test(val);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.information.city = selectedOptions[0].text;// 市
      this.information.county = selectedOptions[1].text;// 区
      if (selectedOptions[2] !== undefined) {
        this.information.town = selectedOptions[2].text;// 街道
      } else {
        this.information.town = '';
      }
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
    // 获取地区
    async region() {
      const res = await regionList();
      let arr = this.changeTree(res.data);
      this.options = arr;
    },
    changeTree(val) {
      //递归删除末级空的children
      let arr = [];
      if (val.length !== 0) {
        val.forEach((item) => {
          let obj = {};
          obj.value = item.id;
          obj.text = item.label;
          if (item.children !== undefined && item.children.length > 0) { //children
            obj.children = this.changeTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
    clickshow() {
      this.showPickers = true;
      // console.log(this.showPickers,'value');
    },
    onConfirm(value) {
      // this.value = value;
      // console.log(value,'value');
      if (value === '医药从业人员') {
        this.information.user_type = '1';
      } else {
        this.information.user_type = '2';
      }
      this.information.typeNmae = value;
      this.showPickers = false;
      // console.log(this.showPicker,'this.showPicker');
    },
    // 注册
    async register() {
      // console.log(this.cascaderValue,'aaa');
      // console.log(this.fieldValue,'aaa');
      const res = await editInfo(this.information);
      // console.log(res,'res');
      if (res.status === 'success') {
        Toast.success('修改成功');
        this.$router.go(-1)
      } else {
        Toast.fail(res.msg);
      }
    
    },
  },
  async mounted() {
   const token = getLocal('token');
    if (!token) {
      this.$router.push({ path: '/login' })
    }
    // 获取用户信息
    const datas = {
      user_id: sessionStorage.getItem('userId-manage'),
    }
    const res = await userinfo(datas.user_id);
    if (res.status === 'success') {
      this.user = { ...res.data };
      this.information.nickname = res.data.nickname;
      this.information.phone = res.data.phone;
      this.information.work_unit = res.data.work_unit;
      this.fieldValue = `${res.data.city}/${res.data.county}/${res.data.town}`;
      this.information.city = res.data.city;
      this.information.county = res.data.county;
      this.information.town = res.data.town;

      this.information.user_type = res.data.user_type;

      if (res.data.user_type == 1) {
        this.information.typeNmae = '医药从业人员';
      } else {
        this.information.typeNmae = '市场监管人员';
      }
      
    } else {
      Toast.fail(res.msg);
    }
    // 获取省市区
    this.region();
  }
}
</script>

<style >

</style>
<style lang="less">
.magaut {
  margin: 0 auto;
  div {
    margin: 0 auto;
    width: 50px;
    text-align: center;
  }
}
// input.van-field__control::-webkit-input-placeholder {
//          color: #4d6ff0;
  
//    }
.login {
  .logo {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 80px auto 0px;
  }
  .logos {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 40px auto 0px;
  }
  .login-body {
    padding: 0 20px;
  }
  .login {
    .link-register {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .register {
    .link-login {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .verify-bar-area {
    margin-top: 24px;
    .verify-left-bar {
      border-color: #00BAFF;
    }
    .verify-move-block {
      background-color: #00BAFF;
      color: #fff;
    }
  }
  .verify {
    >div {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    .cerify-code-panel {
      margin-top: 16px;
    }
    .verify-code {
      width: 40%!important;
      float: left!important;
    }
    .verify-code-area {
      float: left!important;
      width: 54%!important;
      margin-left: 14px!important;
      .varify-input-code {
        width: 90px;
        height: 38px!important;
        border: 1px solid #e9e9e9;
        padding-left: 10px;
        font-size: 16px;
      }
      .verify-change-area {
        line-height: 44px;
      }
    }
  }
}
.inc {
  font-size: 24px;
}
.pdright {
  margin-right: 20px;
}
.magtop {
  margin-top: 60px;
}
.mags {
  margin: 16px;
  margin-top: 140px;
}
.magsd {
  margin: 16px;
  margin-top: 40px;
}
</style>
