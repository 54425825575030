<template>
  <div class="login">
    <Headers :name="'修改密码'"></Headers>
    <div class="logo">账号登录</div>
    <div class="login-body login">
      <van-form @submit="onSubmit" key="formKey">
          <van-field  v-model="oldPassword" :type="passwordType"  label="原密码"  placeholder="请输入原密码"
          :rules="[{ validator:nameVa, message: '请输入字母和数字' }]">
            <i slot="left-icon" class="icon-a-13Dsuotou iconfont inc"></i>
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType">
                  <van-icon name="closed-eye" v-if="passwordType==='password'"/>
                  <van-icon name="eye" v-else />
              </span>
            </template>
          </van-field>
          <van-field  v-model="password" :type="passwordType1"  label="新密码"  placeholder="请输入新密码"
          :rules="[{ validator:nameVa, message: '请输入字母和数字' }]">
            <i slot="left-icon" class="icon-a-13Dsuotou iconfont inc"></i>
            <template slot="right-icon">
              <span class="solts" @click="type1">
                  <van-icon name="closed-eye" v-if="passwordType1==='password'"/>
                  <van-icon name="eye" v-else />
              </span>
            </template>
          </van-field>
           <van-field  v-model="repassword" :type="passwordType2"  label="密码"  placeholder="请输入新密码"
          :rules="[{ validator:nameVa, message: '请输入字母和数字' }]">
            <i slot="left-icon" class="icon-a-13Dsuotou iconfont inc"></i>
             <template slot="right-icon">
              <span class="solts" @click="type2">
                  <van-icon name="closed-eye" v-if="passwordType2==='password'"/>
                  <van-icon name="eye" v-else />
              </span>
            </template>
          </van-field>
        <div class="mags">
          <van-button round block type="info" color="#00BAFF" native-type="submit">确定</van-button>
        </div>
      </van-form>
    </div>
    <div style="margin: 16px;" class="magaut">
      <div @click="forget()">忘记密码</div>
    </div>

   
  </div>
</template>

<script>
import Headers from '@/components/SimpleHeader';
import { editpswd } from '../service/userLogin';
import { setLocal, getLocal } from '@/common/js/utils'
import { Toast } from 'vant'
export default {
  components: {
    Headers
  },
  data() {
    return {
      names: '修改密码',
      passwordType: 'password',//输入框类型
      passwordType1: 'password',//输入框类型
      passwordType2: 'password',//输入框类型
      nameData: '请输入账号',
      passData: '请输入密码',
      oldPassword: '', // 原密码
      password: '', // 新密码
      repassword: '', // 新密码
      type: 'login',
      tabsShow: true,
      information: {
        nickname: '', // 用户名
        phone: '', // 手机号
        work_unit: '', // 单位
        password: '', // 密码
      },
      pattern: /^[a-zA-Z]\w{3,17}$/,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    nameVa(val) {
      return /^[A-Za-z0-9]+$/.test(val);
    },
    // 忘记密码
    forget() {
      // Toast.fail('请联系管理员修改密码!')
      Toast({
        message: '登陆密码均设置为123456，请不要随意更改，如果还是不能登陆，请重新注册，若不能注册，请联系管理员13995867885',
        duration: 3000,
      })
    },
    // 修改密码
    async onSubmit(values) {
      const data = {
        oldPassword: this.oldPassword,
        password: this.password,
        repassword: this.repassword,
        user_id: sessionStorage.getItem('userId-manage'),
      };
      const res = await editpswd(data);
      // console.log(res);
      if (res.status === 'success') {
        Toast.success('修改成功');
        this.$router.push({ path: '/login' })
      } else {
        Toast.fail(res.msg);
      }
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    type1() {
      this.passwordType1 = this.passwordType1 === 'password' ? 'text' : 'password'
    },
    type2() {
      this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
    },
  },
  created() {
   const token = getLocal('token');
    if (!token) {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style >

</style>
<style lang="less">
.magaut {
  margin: 0 auto;
  div {
    margin: 0 auto;
    width: 50px;
    text-align: center;
  }
}
// input.van-field__control::-webkit-input-placeholder {
//          color: #4d6ff0;
  
//    }
.login {
  .logo {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 80px auto 0px;
  }
  .logos {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 40px auto 0px;
  }
  .login-body {
    padding: 0 20px;
  }
  .login {
    .link-register {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .register {
    .link-login {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .verify-bar-area {
    margin-top: 24px;
    .verify-left-bar {
      border-color: #00BAFF;
    }
    .verify-move-block {
      background-color: #00BAFF;
      color: #fff;
    }
  }
  .verify {
    >div {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    .cerify-code-panel {
      margin-top: 16px;
    }
    .verify-code {
      width: 40%!important;
      float: left!important;
    }
    .verify-code-area {
      float: left!important;
      width: 54%!important;
      margin-left: 14px!important;
      .varify-input-code {
        width: 90px;
        height: 38px!important;
        border: 1px solid #e9e9e9;
        padding-left: 10px;
        font-size: 16px;
      }
      .verify-change-area {
        line-height: 44px;
      }
    }
  }
}
.inc {
  font-size: 24px;
}
.pdright {
  margin-right: 20px;
}
.magtop {
  margin-top: 60px;
}
.mags {
  margin: 16px;
  margin-top: 140px;
}
.magsd {
  margin: 16px;
  margin-top: 40px;
}
</style>
