<template>
  <div class="user-box">
    <s-header :name="'学习'"></s-header>
    
   
    <!-- <div class="divflex"> -->
      <!-- <div class="left consTal">
        <van-sidebar v-model="activeKey" @change="onChange">
          <van-sidebar-item v-for="item in listData" :key="item.id" :title="item.title" />
        </van-sidebar>
      </div> -->
      <!-- class="right" -->
      <div >
        <div v-if="arrList.length !== 0">
          <!-- <div class="user-info">
            <span>学习进度：3/50</span>
          </div> -->
          <div class="bottoms" >
            <div class="flex wordimg" v-for="(item,index) in arrList" :key="index" @click="clickData(item,index)">
              <div class="imgs"><img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/></div>
              <!-- <div class="fml-flex-2 size114"></div> -->
              <div class="fml-flex-1 size114">{{item.name}}</div>
            </div>
            <!-- <div class="flex wordimg">
              <div class="imgs"><img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/></div>
              <div class="fml-flex-2 size114">2020年同步考点精讲</div>
              <div class="fml-flex-1 size114">已学习</div>
            </div> -->
          </div>
        </div>
        <div v-else>
          <div class="nodata">暂无数据</div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { studyList } from '../service/userLogin'
import { Sidebar, SidebarItem, Toast } from 'vant';
export default {
  components: {
    sHeader
  },
  data() {
    return {
      listData: [],
      activeKey: 0,
      user: {},
      arrList: [],
    }
  },
  async mounted() {
    const datas = {
      current: 1, // 页码
      pageSize: 999, // 
    }
    this.arrList = [];
    const res = await studyList(datas);
    if (res.status === 'success') {
      // console.log(res);
      this.listData = res.data;
      this.arrList= res.data;
      // console.log(this.listData);
    } else {
      Toast.fail(res.msg);
    }
    // const { data } = await getUserInfo()
    // this.user = data
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(r) {
      this.$router.push({ path: r })
    },
    clickData(item,index) {
      if (item.status_name === '正常') {
        // console.log(item);
        this.$router.push({ 
          path: 'learningContent',
          query:{
            id: item.id,
            category_id: item.category_id,
          }
        })
      } else {
        Toast.fail('学习尚未开启');
      }
      
    },
    onChange(index) {
      // console.log(index);
      // console.log(this.listData[index].children);
      if (this.listData[index].children !== undefined) {
        this.arrList = this.listData[index].children;
      } else {
        this.arrList = [];
      }
      
    },
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/mixin';
.nodata {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 40vh;
}
.size114 {
  padding-right: 10px;
  font-size: 10px;
  text-align: right;
}
.consTal {
  // background-color: red;
  width: 20%;
  height: 40px;
  margin-top: 45px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  margin-right: 16px;
}
.divflex {
  display: flex;
}
.right {
  width: 80%;
  height: 40px;
  margin-top: 50px;
  line-height: 40px;
  font-size: 16px;
  // background-color: #ccc;
}
.user-box {
  padding-top: 40px;
  .user-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .fj();
    .wh(100%, 44px);
    line-height: 44px;
    padding: 0 10px;
    .boxSizing();
    color: #252525;
    background: #fff;
    border-bottom: 1px solid #dcdcdc;
    .user-name {
      font-size: 14px;
    }
  }
  .user-info {
    width: 90%;
    margin: 0 auto;
    height: 40px;
    // margin-top: 50px;
    line-height: 40px;
    font-size: 16px;
    text-align: right;
  }
}
.bottoms {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
  // background-color: yellow;
}
.wordimg {
  border-radius: 6px;
  margin-top: 10px;
  height: 70px;
  background-color: #ccc;
  .imgs {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .textr {
    text-align: right;
  }
}
</style>
