

<template>
  <van-swipe class="my-swipe" :autoplay="5000" indicator-color="#00BAFF">
    <van-swipe-item v-for="(item, index) in list" :key="index">
      <img :src="item.carouselUrl" alt="" @click="goTo(item.url)">
    </van-swipe-item>
  </van-swipe>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: null
    }
  },
  methods: {
    goTo(url) {
      window.open(url)
    }
  }
}
</script>

<style lang='less' scoped>
  .my-swipe .van-swipe-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    object-position: center;
      // height: auto;
      //  transform: scale(0.5);  //缩小0.5倍
      // -ms-transform: scale(0.5);
      // -webkit-transform: scale(0.5);
      // -o-transform: scale(0.5);
      // -moz-transform: scale(0.5);
    }
  }
</style>
