<template>
  <div class="bodsss" >
    <nav-bar></nav-bar>
    <div class="sizehed">孝感市市场监管局药品监管（从业）人员学习平台</div>
    <div class="amagssa">
    <swiper :list="swiperList"></swiper>
    </div>
    <div class="category-list">
      <div @click="goTo('study')">
        <i class="icon-xuexibiji iconfont inc"></i>
        <span>学习</span>
      </div>
      <div @click="goexam()">
        <i class="icon-xuexi iconfont inc"></i>
        <span>考试</span>
      </div>
    </div>

    <div class="bottoms">
      <div class="Notice flex">
        <van-icon class="icons" name="volume" size="18" />
        <div>公告</div>
      </div>
      <div class="flex pads"> 
        <div v-if="studyStart !== ''">
          <div>学习开放时间：</div>
          <div>{{studyStart}}&nbsp;&nbsp;至&nbsp;&nbsp;{{studyEnd}}</div>
          <!-- 学习开放时间：{{studyStart}} -->
        </div>
        
      </div>
      <div class="flex pads"> 
        <div v-if="examStart !== ''">
          <div>考试开放时间：</div>
          <div>{{examStart}}&nbsp;&nbsp;至&nbsp;&nbsp;{{examEnd}}</div>
          <!-- 学习开放时间：{{studyStart}} -->
        </div>
        <div v-else>
          <div>暂无考试开放中</div>
          <div></div>
          <!-- 学习开放时间：{{studyStart}} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '@/components/NavBar'; // 底部
import swiper from '@/components/Swiper'
import { getHome } from '../service/home'
// import { getUserInfo } from '../service/user'
import { getLocal, tokenChang } from '@/common/js/utils'
import { studyExaminationTime, examList, examcatelist } from '../service/userLogin'
import { Toast } from 'vant';
// import img1 from '@/assets/banner1.jpg'
// import img2 from '@/assets/banner2.jpg'
// import img3 from '@/assets/banner3.jpg'
import img1 from '@/assets/hbs.jpg'
import img2 from '@/assets/xggm.png'

import img3 from '@/assets/scjd.jpg'
export default {
  name: 'home',
  data() {
    return {
      examStart: '',
      examEnd: '',
      studyStart: '',
      studyEnd: '',
      swiperList: [
        {
          carouselUrl: img1,
          url: 'https://mpa.hubei.gov.cn/',
        },
        {
          carouselUrl: img2,
          url: 'https://www.xiaogan.gov.cn/',
        },
        {
          carouselUrl: img3,
          url: 'http://scjg.xiaogan.gov.cn/',
        }
      ],
      headerScroll: false,
    }
  },
  components: {
    navBar,
    swiper
  },
  watch: {
  
  },
  async mounted() {
    tokenChang();
    const token = getLocal('token');
    if (!token) {
      this.$router.push({ path: '/login' })
    }
    
    // window.addEventListener('scroll', this.pageScroll)
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    });
    const res = await studyExaminationTime();
    if (!res) return;
    // console.log(res);
    // 考试时间
    this.examStart = res.data.exam_time.exam_start;
    this.examEnd = res.data.exam_time.exam_end;
    // 学习时间
    this.studyStart = res.data.study_time.study_start;
    this.studyEnd = res.data.study_time.study_end;
    // this.examStart = '';

    // this.swiperList = data.carousels
    Toast.clear()
  },
  destroyed() {
		// 组件销毁后解绑事件
		window.onresize = null;
	},
  methods: {
    pageScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      scrollTop > 100 ? this.headerScroll = true : this.headerScroll = false
    },
    goToDetail(item) {
      this.$router.push({ path: `product/${item.goodsId}` })
    },
    goTo(router) {
      let start = this.tinmeDate(this.studyStart);
      let end = this.tinmeDate(this.studyEnd);
      
      let dq = new Date().getTime();
      if (dq > start && dq < end) {
        
        this.$router.push({ path: router });
      } else {
        Toast.fail('学习尚未开启');
      }
    },
    // 考试
    async goexam() {
      let start = this.tinmeDate(this.examStart);
      let end = this.tinmeDate(this.examEnd);
      let dq = new Date().getTime();
      // this.$router.push({ path: '/examList' });
      // 是否开启考试
      const data = await examcatelist();
      if (!data) return;
      if (data.status === 'success') {
        this.$router.push({ path: '/examList' });
      } else {
        Toast.fail('考试尚未开启');
      }
    },
    tinmeDate(datas) {
      let date = datas;
      date = date.substring(0,19);    
      date = date.replace(/-/g,'/');
      let timestamp = new Date(date).getTime();
      return timestamp;
    }
  }
}
</script>

<style lang="less" scoped >
@import '../common/style/mixin';
.sizehed {
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}
.bodsss {
  width: 100%;
  height: 100%;
  overflow-x:scroll;
}
.amagssa {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
.category-list {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 13px;
  // background-color: #ccc;
  width: 80%;
  margin: 0 auto;
  height: 100px;
  margin-top: 20px;
  div {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
    margin-top: 25px;
    span {
      font-weight: 700;
      text-rendering: optimizeLegibility;
      font-kerning: normal;
    }
  }
}
.inc {
  font-size: 36px;
}
.bottoms {
  // background-color: #ccc;
  width: 80%;
  margin: 0 auto;
  height: 150px;
  margin-top: 20px;
  .Notice {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    div {
      margin-top: 4px;
      margin-left: 6px;
      line-height: 30px;
    }
  }
  .pads {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    div {
      // font-size: 16px;
      font-size: 10px;
    }
  }
}
.magtop {
  margin-top: 4px;
}
</style>
