import { render, staticRenderFns } from "./edit-user.vue?vue&type=template&id=cb90c9c8&"
import script from "./edit-user.vue?vue&type=script&lang=js&"
export * from "./edit-user.vue?vue&type=script&lang=js&"
import style1 from "./edit-user.vue?vue&type=style&index=1&id=cb90c9c8&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports