<template>
  <div class="user-box">
    <s-header :name="'我的'"></s-header>
    <div class="user-info">
      <div class="info">
        <img src="//s.weituibao.com/1583583975067/user-graduate%20(1).png"/>
        <div class="user-desc">
          <span>昵称：{{ user.nickname }}</span>
          <span>所属单位：{{ user.work_unit }}</span>
          <span class="name">电话号：{{ user.phone }}</span>
        </div>
      </div>
    </div>
    <ul class="user-list">
      <li @click="goTo('steganogram')">
        <span>修改密码</span>
        <van-icon name="arrow" />
      </li>
      <li @click="goTo('editUser')">
        <span>用户设置</span>
        <van-icon name="arrow" />
      </li>
      <li @click="signOut()">
        <span>退出登录</span>
        <van-icon name="arrow" />
      </li>
      <!-- <li @click="goTo('setting')">
        <span>账号管理</span>
        <van-icon name="arrow" />
      </li>
      <li @click="goTo('address?from=mine')">
        <span>地址管理</span>
        <van-icon name="arrow" />
      </li>
      <li @click="goTo('about')">
        <span>关于我们</span>
        <van-icon name="arrow" />
      </li> -->
    </ul>
    <nav-bar></nav-bar>
  </div>
</template>

<script>
import navBar from '@/components/NavBar';
import sHeader from '@/components/SimpleHeader';
import { userinfo } from '../service/user'
import { getLocal, tokenChang } from '@/common/js/utils';
import { Toast } from 'vant';
// import { userinfo } from '../service/userLogin';
export default {
  components: {
    navBar,
    sHeader
  },
  data() {
    return {
      user: {}
    }
  },
  async mounted() {
    tokenChang();
    const token = getLocal('token');
    if (!token) {
      this.$router.push({ path: '/login' })
    }
    const datas = {
      user_id: sessionStorage.getItem('userId-manage'),
    }
    const res = await userinfo(datas.user_id);
    if (res.status === 'success') {
      // console.log(res);
      this.user = { ...res.data };
    } else {
      Toast.fail(res.msg);
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goTo(name) {
      this.$router.push({ path: name })
    },
    signOut() {
      this.$router.push({ path: '/login' });
    },
  }
}
</script>

<style lang="less" scoped>
  @import '../common/style/mixin';
  .user-box {
    .user-header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
      .fj();
      .wh(100%, 44px);
      line-height: 44px;
      padding: 0 10px;
      .boxSizing();
      color: #252525;
      background: #fff;
      border-bottom: 1px solid #dcdcdc;
      .user-name {
        font-size: 14px;
      }
    }
    .user-info {
      width: 94%;
      margin: 10px;
      height: 115px;
      background: linear-gradient(90deg, @primary, #51c7c7);
      box-shadow: 0 2px 5px #269090;
      border-radius: 6px;
      margin-top: 50px;
      .info {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 25px 20px;
        .boxSizing();
        img {
          .wh(60px, 60px);
          border-radius: 50%;
          margin-top: 4px;
        }
        .user-desc {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          line-height: 20px;
          font-size: 14px;
          color: #fff;
          span {
            color: #fff;
            font-size: 14px;
            padding: 2px 0;
          }
        }
        .account-setting {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 13px;
          color: #fff;
          .van-icon-setting-o {
            font-size: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
        }
      }
    }
    .user-list {
      padding: 0 20px;
      margin-top: 20px;
      li {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .van-icon-arrow {
          margin-top: 13px;
        }
      }
    }
  }
</style>
