<template>
  <div class="login">
    <div class="sizehed">孝感市市场监管局药品监管（从业）人员学习平台</div>
    <div class="logo" v-if="tabsShow === false">账号登录</div>
    <div class="login-body login" v-if="tabsShow === false">
      <van-form @submit="onSubmit" key="formKey">
        <!-- left-icon="manager" -->
          <van-field  v-model="username"  label="账号"  clearable  placeholder="请输入手机号"
          :rules="[{ validator:iphone, message: '填写有误，请输入手机号' }]">
          <i slot="left-icon" class="icon-yonghu iconfont inc"></i>
          </van-field>
          <van-field  v-model="password" type="password"  label="密码"  clearable  placeholder="请输入密码"
          :rules="[{ validator:nameVa, message: '填写有误，请输入字母和数字' }]">
            <i slot="left-icon" class="icon-a-13Dsuotou iconfont inc"></i>
          </van-field>
        <div class="mags">
          <van-button round block type="info" color="#00BAFF" native-type="submit">登录</van-button>
        </div>
      </van-form>
    </div>
    <div style="margin: 16px;" class="flex" v-if="tabsShow === false">
      <div class="fml-flex-2 textRight pdright" @click="newUser">新用户注册</div>
      <div class="fml-flex-2" @click="forget()">忘记密码</div>
    </div>

    <div class="logos" v-if="tabsShow === true">新用户注册</div>
    <div class="titSiz" v-if="tabsShow === true">(药品从业人员注册请将密码统一设置为与登陆手机号一致)</div>
    <div class="login-body login" v-if="tabsShow === true">
      <van-form @submit="register" key="formKey" ref="loginFormRef">
          <van-field  v-model="information.nickname"  required clearable  placeholder="请输入姓名"
           :rules="[{ validator:sss, message: '填写有误，请勿输入特殊字符' }]">
           <i slot="left-icon" class="icon-yonghu iconfont inc"></i>
          </van-field>
           <van-field  v-model="information.phone" required clearable  placeholder="请输入手机号"
           :rules="[{ validator:iphone, message: '填写有误，请输入正确的号码' }]">
            <i slot="left-icon" class="icon-shouji iconfont inc"></i>
          </van-field>
           <van-field  v-model="information.work_unit" required clearable  placeholder="请输入单位名称"
           :rules="[{ required: true, message: '请填写单位名称' }]">
            <i slot="left-icon" class="icon-gongsi iconfont inc"></i>
            
          </van-field>

          <van-field  v-model="information.typeNmae" required is-link readonly  placeholder="请选择人员类型"  :rules="[{ required: true, message: '请选择人员类型' }]" @click="clickshow">
            <!-- @click="clickshow" -->
            <i slot="left-icon" class="iconfont icon-renyuanleixing  inc"></i>
            <!-- &#xe62c; icon-renyuanleixing-->
           </van-field>
           <!-- <van-field name="radio" label="人员类型"  required :rules="[{ required: true, message: '请选择人员类型' }]">
              <template #input>
                <van-radio-group v-model="information.user_type" direction="horizontal">
                  <van-radio name="1">医药从业人员</van-radio>
                  <van-radio name="2" class="mgtrad">市场监管人员</van-radio>
                </van-radio-group>
              </template>
            </van-field> -->

          <van-field v-model="fieldValue" required is-link readonly  placeholder="请选择药店所属地区"
          :rules="[{ required: true, message: '请选择药店所属地区' }]"
            @click="show = true" >
            <i slot="left-icon" class="iconfont icon-shengfenzuobiao_mianxing  inc"></i>
            
          </van-field>
           <!-- <van-field  v-model="information.username" required clearable  placeholder="请输入账号"
           :rules="[{ validator:nameVa, message: '填写有误，请输入字母和数字' }]">
            <i slot="left-icon" class="icon-yonghu iconfont inc"></i>
          </van-field> -->
          <van-field  v-model="information.password" type="password" required clearable  placeholder="请输入密码"
          :rules="[{ validator:nameVa, message: '填写有误，请输入字母和数字' }]">
            <i slot="left-icon" class="icon-a-13Dsuotou iconfont inc"></i>
          </van-field>
        <div class="magsd">
          <van-button round block type="info" color="#00BAFF" native-type="submit">注册</van-button>
        </div>
      </van-form>
    </div>
    <div style="margin: 16px;" class="flex" v-if="tabsShow === true">
      <div class="fml-flex-2 textRight pdright" @click="useLonig">账号登录</div>
      <div class="fml-flex-2" @click="forget()">忘记密码</div>
    </div>

    <van-dialog v-model="dialogShow" title="提示" :showConfirmButton='trueS'>
      <div class="contsize">请注册人员严格按照自己真实信息填写对应所属街道、所属单位，如若发现恶意填写他人单位，一经发现将严肃处理并通报，严重的追究法律责任</div>
      <!-- :before-close="onBeforeClose" @confirm="signSupplementaryProtocol" -->
      <div class="pdes">
      <van-button type="primary" round block class="btntop" color="#00BAFF" :disabled="disarr" @click="onBeforeClose">
        确定 
        <span v-show="num !== 0">{{timeNum}}s</span>
      </van-button>
      </div>
    </van-dialog>
    <van-popup v-model="showPickers" round position="bottom">
      <van-picker show-toolbar :columns="columns"  @cancel="showPickers = false"  @confirm="onConfirm"  />
    </van-popup>

    <van-popup v-model="show" round position="bottom">
      <van-cascader  v-model="cascaderValue" title="请选择所属地区"  :options="options"
        @close="show = false"  @finish="onFinish" />
    </van-popup>

    <van-dialog v-model="resettingb" title="提示" :showConfirmButton='resettings'>
      <div class="contsize tecen">您的登录密码已重置为123456</div>
      <!-- :before-close="onBeforeClose" @confirm="signSupplementaryProtocol" -->
      <div class="pdes">
      <van-button type="primary" round block class="btntop" color="#00BAFF" @click="clcikdel">
        确定 
      </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
// import sHeader from '@/components/SimpleHeader'

import { loginName, registerName, regionList, } from '../service/userLogin';
import { setLocal, getLocal } from '@/common/js/utils'
import { Toast,Dialog,Button  } from 'vant'
export default {
  data() {
    return {
      showPickers: false,
      disarr: true,
      trueS: false,
      resettings: false,
      resettingb: false,
      dialogShow: false,
      nameData: '请输入账号',
      passData: '请输入密码',
      username: '',
      password: '',
      type: 'login',
      tabsShow: true,
      information: {
        nickname: '', // 用户名
        phone: '', // 手机号
        work_unit: '', // 单位
        password: '', // 密码
        city: '', // 市
        county: '', // 区
        town: '', // 街道
        user_type: '', // 用户类型
        typeNmae: '医药从业人员',
      },
      pattern: /^[a-zA-Z]\w{3,17}$/,
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [],
      columns: [ '医药从业人员', '市场监管人员' ],
      timeNum: 0,
      num: 0,
    }
  },
  components: {
  },
  methods: {
    onConfirm(value) {
      // this.value = value;
      // console.log(value,'value');
      if (value === '医药从业人员') {
        this.information.user_type = '1';
      } else {
        this.information.user_type = '2';
      }
      this.information.typeNmae = value;
      this.showPickers = false;
      // console.log(this.showPicker,'this.showPicker');
    },
    clickshow() {
      this.showPickers = true;
      // console.log(this.showPickers,'value');
    },
    sss(val) {
      // console.log(val,'val');
      return /^([\u4e00-\u9fa5]|[a-zA-Z0-9])+$/.test(val);
    },
    iphone(val) {
      // console.log(val,'val');
      // 验证一个手机号：第一位是1，第二位是3-9，以后每一位都是0-9
      // /^[1][3,4,5,7,8][0-9]{9}$/
      return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
    },
    nameVa(val) {
      return /^[A-Za-z0-9]+$/.test(val);
    },
    useLonig() {
      this.tabsShow = false;
      this.username = '';
      this.password = '';
    },
    // 新用户注册
    newUser() {
      this.disarr = true;
      this.tabsShow = true;
      this.fieldValue = '';
      this.cascaderValue = '';
      this.information.typeNmae = '医药从业人员';
      this.information.user_type = '1';
      this.dialogShow = true;
      this.num = 6;
      this.timeNum = 6;
      this.setout();
      setTimeout(() => {
        this.disarr = false;
      },6000)
      // },30)
    },
    setout() {
      let timer = setInterval(() => {
        this.num = this.num - 1;
        this.timeNum = this.num;
        // console.log(this.timeNum,'this.timeNum');
        if (this.timeNum === 0) {
          clearInterval(timer);
        }
      },1000)
    },
    onBeforeClose() {
      this.dialogShow = false;
    },
    clcikdel() {
      this.resettingb = false;
    },
    signSupplementaryProtocol() {
      this.dialogShow = false;
    },
    beforeClose(action, done) {

    },
    // 忘记密码
    forget() {
      // 忘记密码管理员电话：13995867885
      Toast({
        message: '登陆密码均设置为123456，请不要随意更改，如果还是不能登陆，请重新注册，若不能注册，请联系管理员13995867885',
        duration: 3000,
      })
    },
    // 登录
    async onSubmit(values) {
      const log = {
        phone: this.username,
        password: this.password,
      };
      const res = await loginName(log);
      // console.log(res);
      if (res.status === 'success') {
        this.tabsShow = false;
        sessionStorage.setItem('token',res.data.token);
        sessionStorage.setItem('userId-manage', res.data.id);
        this.$router.push({ path: '/home' })
      } else {
        Toast.fail(res.msg);
      }
    },
    // 注册
    async register() {
      // console.log(this.cascaderValue);
      // console.log(this.information,'this.information');
      // return
      const res = await registerName(this.information);
      if (res.status === 'success') {
        Toast.success('注册成功');
        this.tabsShow = false;
      } else {
        Toast.fail(res.msg);
      }
    },
    // 获取地区
    async region() {
      const res = await regionList();
      let arr = this.changeTree(res.data);
      this.options = arr;
    },
     // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.information.city = selectedOptions[0].text;// 市
      this.information.county = selectedOptions[1].text;// 区
      if (selectedOptions[2] !== undefined) {
        this.information.town = selectedOptions[2].text;// 街道
      } else {
        this.information.town = '';
      }
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
    },
    changeTree(val) {
      //递归删除末级空的children
      let arr = [];
      if (val.length !== 0) {
        val.forEach((item) => {
          let obj = {};
          obj.value = item.id;
          obj.text = item.label;
          if (item.children !== undefined && item.children.length > 0) { //children
            obj.children = this.changeTree(item.children);
          }
          arr.push(obj);
        });
      }
      return arr;
    },
  },
  created() {
    // Toast({
    //   message: '您的登录密码已重置为123456',
    //   duration: 2000,
    // })
    this.resettingb = true;
    this.tabsShow = false;
    localStorage.setItem('loglevel:webpack-dev-server','SILENT'); // 避免vue报错
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userId-manage');
    this.region();
  }
}
</script>

<style >

</style>
<style lang="less">

.titSiz {
  text-align: center;
  font-size: 12px;
  color: red;
}
// @import '../common/js/demo.css';
.mgtrad {
  margin-top: 6px;
}
.sizehed {
  padding-top: 20px;
  height: 40px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  background-color: #00BAFF;
}
.contsize {
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 16px;
}
.pdes {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}
.btntop {
  
  width: 100%;
  // border-radius: 4px;
  // display: block;
  margin-top: 20px;
}
// input.van-field__control::-webkit-input-placeholder {
//          color: #4d6ff0;
  
//    }
.login {
  .logo {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 80px auto 0px;
  }
  .logos {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin: 40px auto 0px;
  }
  .login-body {
    padding: 0 20px;
  }
  .login {
    .link-register {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .register {
    .link-login {
      font-size: 14px;
      margin-bottom: 20px;
      color: #1989fa;
      display: inline-block;
    }
  }
  .verify-bar-area {
    margin-top: 24px;
    .verify-left-bar {
      border-color: #00BAFF;
    }
    .verify-move-block {
      background-color: #00BAFF;
      color: #fff;
    }
  }
  .verify {
    >div {
      width: 100%;
    }
    display: flex;
    justify-content: center;
    .cerify-code-panel {
      margin-top: 16px;
    }
    .verify-code {
      width: 40%!important;
      float: left!important;
    }
    .verify-code-area {
      float: left!important;
      width: 54%!important;
      margin-left: 14px!important;
      .varify-input-code {
        width: 90px;
        height: 38px!important;
        border: 1px solid #e9e9e9;
        padding-left: 10px;
        font-size: 16px;
      }
      .verify-change-area {
        line-height: 44px;
      }
    }
  }
}
.inc {
  font-size: 24px;
}
.pdright {
  margin-right: 20px;
}
.magtop {
  margin-top: 60px;
}
.mags {
  margin: 16px;
  margin-top: 140px;
}
.magsd {
  margin: 16px;
  margin-top: 40px;
}
.tecen {
  text-align: center;
}
</style>
