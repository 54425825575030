import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'

// axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://backend-api-01.newbee.ltd/api/v1' : 'http://backend-api-01.newbee.ltd/api/v1'
// axios.defaults.baseURL = 'http://36.134.79.236:9090';
axios.defaults.baseURL = 'https://api.xgscdy.com';
// axios.defaults.baseURL = 'localhost:9090';
// axios.defaults.withCredentials = true;  // 跨域请求时发送Cookie
axios.defaults.timeout = 30000; // 请求超时
// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';



// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['token'] = sessionStorage.getItem('token') || '';

axios.defaults.headers.post['Content-Type'] = 'application/json'

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8;';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(config => {
  // console.log(config,'config');
  if (config.method === 'get') {
    //  给data赋值以绕过if判断
    config.data = true
  }
  // config.headers['Content-Type'] = 'application/text'
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  // config.headers['Content-Type'] = 'multipart/form-data';
  // config.headers['Content-Type'] = 'text/plain'; 
  // config.headers['Content-Type'] = 'application/json;charset=utf-8';
  return config
}, err => Promise.reject(err))

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    // console.log(res.data);
    if (res.data === '无效的token...') {
      return router.push({ path: '/login' })
    }
    Toast.fail('服务端异常！')
    return Promise.reject(res)
  }
  if (res.data.msg === '用户信息获取失败') {
    return router.push({ path: '/login' })
  }
  // console.log(res);
  if (res.status !== 200) { // 如果请求头不是成功
    // console.log(res);
    if (res.data.status) Toast.fail(res.data.status)
    if (res.data.status == 416) {
      router.push({ path: '/login' })
    }
    return Promise.reject(res.data)
  }

  return res.data
})

export default axios
